exports.components = {
  "component---node-modules-weahead-gatsby-theme-princess-cake-src-pages-404-js": () => import("./../../../node_modules/@weahead/gatsby-theme-princess-cake/src/pages/404.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-princess-cake-src-pages-404-js" */),
  "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-aboutpage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-princess-cake/src/templates/Aboutpage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-aboutpage-js" */),
  "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-eventpage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-princess-cake/src/templates/Eventpage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-eventpage-js" */),
  "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-frontpage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-princess-cake/src/templates/Frontpage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-frontpage-js" */),
  "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-gallery-js": () => import("./../../../node_modules/@weahead/gatsby-theme-princess-cake/src/templates/Gallery.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-gallery-js" */),
  "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-gallerypage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-princess-cake/src/templates/Gallerypage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-gallerypage-js" */),
  "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-news-article-js": () => import("./../../../node_modules/@weahead/gatsby-theme-princess-cake/src/templates/NewsArticle.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-news-article-js" */),
  "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-newspage-js": () => import("./../../../node_modules/@weahead/gatsby-theme-princess-cake/src/templates/Newspage.js" /* webpackChunkName: "component---node-modules-weahead-gatsby-theme-princess-cake-src-templates-newspage-js" */)
}

